(function($) {
    //add Element Actions on elementor init
    $(window).on('elementor/frontend/init', function() {

        elementorFrontend.hooks.addAction( 'frontend/element_ready/toggle.default', function($scope){
            $(".od-collapse--hotspot").click(function() {
                $('.od-collapse--hotspot').removeClass('od-collapse--hotspot-active');
                $(this).toggleClass('od-collapse--hotspot-active');
            });
        });


        elementorFrontend.hooks.addAction( 'frontend/element_ready/form.default', function($scope){
            $scope.find('input, textarea').focus(function(e) {
                var name = $(this).attr('id');
                if ($(this).val()) {
                    $('label[for="' + name + '"]').addClass('active');
                }
                if ($('label[for="' + name + '"]').length > 0) {
                    $('label[for="' + name + '"]').addClass('active');
                }
            });

            $scope.find('input, textarea').blur(function(e) {
                var name = $(this).attr('id');
                if ($('label[for="' + name + '"]').length > 0) {
                    $('label[for="' + name + '"]').removeClass('active');
                }
                if ($(this).val()) {
                    $('label[for="' + name + '"]').addClass('active');
                }
            });

            $('#form-field-register_more-0').click(function(){
                if($(this).prop("checked") == true){
                    $('.elementor-field-group-seriennummer_2, .elementor-field-group-typ_2, .elementor-field-group-gerat_2').addClass('d-block');
                }
                else if($(this).prop("checked") == false){
                    $('.elementor-field-group-seriennummer_2, .elementor-field-group-typ_2, .elementor-field-group-gerat_2').removeClass('d-block');
                }
            });
        });

        elementorFrontend.hooks.addAction( 'frontend/element_ready/n1-custom-swiper.default', function($scope){
            var thisSwipterId = $scope.find('.swiper-container').attr('id');
            if (thisSwipterId) {
                var swiper = new Swiper('#'+thisSwipterId, {
                    slidesPerView: 'auto',
                    spaceBetween: 24,
                    centeredSlides: false,
                    loop: false,
                    pagination: {
                        el: '#'+thisSwipterId + ' .swiper-pagination',
                        clickable: true,
                    },
                    navigation: {
                        nextEl: '#'+thisSwipterId + ' .swiper-button-next',
                        prevEl: '#'+thisSwipterId + ' .swiper-button-prev',
                    },
                });
            }
        });


        elementorFrontend.hooks.addAction( 'frontend/element_ready/n1-contentpage-mobile-swiper.default', function($scope){

            var swiper = Swiper;
            var init = false;

            function swiperMode() {
                var mobile = window.matchMedia('(min-width: 0px) and (max-width: 767px)');
                var desktop = window.matchMedia('(min-width: 768px)');

                if(mobile.matches) {
                    if (!init) {
                        init = true;

                        var thisSwipterId = $scope.find('.swiper-container').attr('id');
                        if (thisSwipterId) {
                            swiper = new Swiper('#'+thisSwipterId, {
                                slidesPerView: '1',
                                spaceBetween: 20,
                                centeredSlides: false,
                                loop: false,
                                pagination: {
                                    el: '#'+thisSwipterId + ' .swiper-pagination',
                                    type: 'fraction',
                                },
                                navigation: {
                                    nextEl: '#'+thisSwipterId + ' .swiper-button-next',
                                    prevEl: '#'+thisSwipterId + ' .swiper-button-prev',
                                },
                                breakpoints: {
                                    640: {
                                        slidesPerView: 2,
                                    },
                                    768: {
                                        slidesPerView: 1,
                                    }
                                }
                            });
                        }
                    }
                } else if(desktop.matches) {
                    if(init){
                        swiper.destroy();
                        init = false;
                    }
                }
            }

            /* On Load
            **************************************************************/
            window.addEventListener('load', function() {
                swiperMode();
            });

            /* On Resize
            **************************************************************/
            window.addEventListener('resize', function() {
                swiperMode();
            });
        });


        elementorFrontend.hooks.addAction( 'frontend/element_ready/n1-product-tab-swiper.default', function($scope){

            var thisSwipterId = $scope.find('.od-product-tab-swiper__content-slider').attr('id');
            var double_content_swiper = new Swiper('#'+thisSwipterId, {
                slidesPerView: '1',
                spaceBetween: 0,
                watchSlidesProgress: true,
                allowTouchMove: false,
                observer: true,
                observeParents: true,
                768: {
                    slidesPerView: 1,
                }
            });


            var thisSwipterId = $scope.find('.od-product-tab-swiper__image-slider').attr('id');
            var double_image_swiper = new Swiper('#'+thisSwipterId, {
                slidesPerView: '1',
                spaceBetween: 20,
                centeredSlides: true,
                observer: true,
                observeParents: true,
                pagination: {
                    el: '#'+thisSwipterId + ' .swiper-pagination',
                    type: 'fraction',
                },
                navigation: {
                    nextEl: '#'+thisSwipterId + ' .swiper-button-next',
                    prevEl: '#'+thisSwipterId + ' .swiper-button-prev',
                },
                breakpoints: {
                    768: {
                        slidesPerView: 'auto',
                        spaceBetween: 0,
                    }
                },
                thumbs: {
                    swiper: double_content_swiper
                }
            });
        });

        elementorFrontend.hooks.addAction( 'frontend/element_ready/n1-product-equipment-swiper.default', function($scope){
            var thisSwipterId = $scope.find('.swiper-container').attr('id');
            if (thisSwipterId) {
                var swiper = new Swiper('#'+thisSwipterId, {
                    slidesPerView: 1,
                    spaceBetween: 20,
                    navigation: {
                        nextEl: '#'+thisSwipterId + ' .swiper-button-next',
                        prevEl: '#'+thisSwipterId + ' .swiper-button-prev',
                    },
                    pagination: {
                        el: '#'+thisSwipterId + ' .swiper-pagination',
                        type: 'fraction',
                    },
                    breakpoints: {
                        576: {
                            slidesPerView: 2,
                        },
                        768: {
                            slidesPerView: 3,
                        },
                        1025: {
                            slidesPerView: 4
                        }
                    },
                });
            }
        });

        elementorFrontend.hooks.addAction( 'frontend/element_ready/hotspot.default', function($scope){
            var thisHotspotId = $scope.attr('id');
   
            if (window.innerWidth < 768) {
                $('#'+thisHotspotId+' .e-hotspot').click(function() {
                    if($( this ).hasClass("e-hotspot--active")){
                        $('#map_mobile_content__'+thisHotspotId+'').css('display', 'none');
                    } else {
                        var clickedContent = $( this ).find('.e-hotspot__tooltip').html();
                        $('#map_mobile_content__'+thisHotspotId+'').css('display', 'block');
                        $('#map_mobile_content__'+thisHotspotId+'').html('<div class="map_mobile_content_wrapper">'+clickedContent+'</div>');
                    }
                });
                $('#'+thisHotspotId+' .e-hotspot--active').click(function() {
                   
                });
            }
        });


        elementorFrontend.hooks.addAction( 'frontend/element_ready/n1-single-equipment-swiper.default', function($scope){
            var thisSwipterId = $scope.find('.swiper-container').attr('id');
            if (thisSwipterId) {
                var swiper = new Swiper('#'+thisSwipterId, {
                    slidesPerView: '1',
                    spaceBetween: 10,
                    loopedSlides: 5,
                    centeredSlides: true,
                    loop: true,
                    navigation: {
                        nextEl: '#'+thisSwipterId + ' .swiper-button-next',
                        prevEl: '#'+thisSwipterId + ' .swiper-button-prev',
                    },
                    pagination: {
                        el: '#'+thisSwipterId + ' .swiper-pagination',
                        type: 'fraction',
                    },
                    breakpoints: {
                        768: {
                            spaceBetween: 20,
                        }
                    },
                });
            }
        });

        // BLOG POST SWIPER
        if (document.querySelector('.od-post-swiper__image-slider')) {
            var swiper = new Swiper('.od-post-swiper__image-slider', {
                slidesPerView: '1',
                spaceBetween: 14,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                pagination: {
                    el: '.swiper-pagination',
                    type: 'fraction',
                },
                breakpoints: {
                    768: {
                        slidesPerView: '2',
                    },
                    1025: {
                        slidesPerView: '3',
                    }
                },
            });
        }


        // Haendlerlogin

        elementorFrontend.hooks.addAction( 'frontend/element_ready/shortcode.default', function($scope){
            if ($('#rememberme').is(':checked')){
                $('.login-remember>label').addClass('active');
            } else {
                $('.login-remember>label').removeClass('active');
            }

            $scope.find('input, textarea').focus(function(e) {
                var name = $(this).attr('id');
                if ($(this).val()) {
                    $('label[for="' + name + '"]').addClass('active');
                }
                if ($('label[for="' + name + '"]').length > 0) {
                    $('label[for="' + name + '"]').addClass('active');
                }
            });

            $scope.find('input, textarea').blur(function(e) {
                var name = $(this).attr('id');
                if ($('label[for="' + name + '"]').length > 0) {
                    $('label[for="' + name + '"]').removeClass('active');
                }
                if ($(this).val()) {
                    $('label[for="' + name + '"]').addClass('active');
                }
            });
        });
    });

    // SINGLE PRODUCT SLIDER
    $(document).ready(function() {
        if (document.querySelector('.hero-slider-thumbs')) {
            var galleryThumbs = new Swiper('.hero-slider-thumbs', {
                spaceBetween: 10,
                slidesPerView: 4,
                freeMode: true,
                watchSlidesVisibility: true,
                watchSlidesProgress: true,
                breakpoints: {
                    768: {
                        slidesPerView: '5',
                    }
                },
            });
            var galleryTop = new Swiper('.hero-slider-top', {
                spaceBetween: 10,
                navigation: {
                    nextEl: '.hero-slider-thumbs__btn--next',
                    prevEl: '.hero-slider-thumbs__btn--prev',
                },
                thumbs: {
                    swiper: galleryThumbs
                }
            });
        }
    });


    // MOBILE MENU

    $(document).ready(function() {
        jQuery( document ).on( 'elementor/popup/show', () => {
            // === Moblie Menu Layout ===
            var $Menu = $('.mobile-menu');
            var $ActiveLink = $Menu.find('li').has('ul').children('a');
            var $MenuChildUl = $Menu.find('li ul');
            // Active menu when click link on li
            $ActiveLink.off('click').on('click', function() {
                $(this).closest('li').addClass('active');
                $(this).closest('ul').addClass('active');
                $('.mobile-menu').addClass('active');
                $('.ul-node.active').scrollTop(0);
            });

            // Add event to back button
            $MenuChildUl.find('.mobile-menu__back-btn').on('click', function(event) {
                event.preventDefault();
                $(this).closest('li.active').removeClass('active');
                $(this).closest('ul.active').removeClass('active');
                $('.mobile-menu.active').removeClass('active');
            });
            console.log($MenuChildUl.find('.mobile-menu__breadcrumb'));
            // Add event to back button
            $MenuChildUl.find('.mobile-menu__breadcrumb').on('click', function(event) {
                event.preventDefault();
                let newIndex = $(this).data('index');
                $Menu.find('.ul-node.active').each(function(){
                   if($(this).data('index') === newIndex){
                       $(this).find('.active').removeClass('active');
                   }
                });
            });
        } );
    });


    // Facet WP
    function moveOtherToEnd() {
        $('.elementor-shortcode .facetwp-hierarchy_select').each(function() {
            var select = this;
            $(this).find('option').each(function () {
                if(this.value.indexOf('andere') > -1) {
                    $(this).appendTo(select)
                }
            })
        });
    }
    $(document).on('facetwp-loaded', function() {
        moveOtherToEnd();
    });
    moveOtherToEnd();


    // Event Form
    if($('#form-field-messename').length){
        $(window.eventsArray).each(function(index, item){
            $('#form-field-messename').append(new Option(item.event, item.event));
        })
        $('#form-field-messename').change(function(){
            var value = $('#form-field-messename').val();
            $(window.eventsArray).each(function(index, item) {
                if(item.event === value){
                    $('#form-field-field_date').attr('min', item.date_begin);
                    $('#form-field-field_date').attr('max', item.date_end);
                }
            });
        });
    }

    // DEALER POPUP V2
    $('body').on('click', '#pills-baumaschinen2 .od-dealer-img-check', function(){
        var $thisMaschine = $(this);
        $thisMaschine.toggleClass('od-dealer-img-check--active')
        var allMaschine = $("#pills-baumaschinen2 .od-dealer-img-check--active .widget-image-caption").map(function() {
            return this.innerHTML;
        }).get();
        $('#form-field-hidden_maschine').val();
        $('#form-field-hidden_maschine').val(allMaschine.join());
    });
    $('body').on('click', '#pills-ladekran2 .od-dealer-img-check', function(){
        var $thisKran = $(this);
        $thisKran.toggleClass('od-dealer-img-check--active')
        var allKran = $("#pills-ladekran2 .od-dealer-img-check--active .widget-image-caption").map(function() {
            return this.innerHTML;
        }).get();
        $('#form-field-hidden_kran').val();
        $('#form-field-hidden_kran').val(allKran.join());
    });

    // DEALER POPUP BUTTON

    $('body').on('click', '.n1-dealer-popup-button', function(){
        var $this = $(this);
        var checkExist = setInterval(function() {
            if ($('.dialog-widget-content').find('.modal-sidebar-wrapper').length) {
                $('.dialog-widget-content').find('#popup-img-id img').removeAttr('width').removeAttr('height').attr('src', $this.data('img-url'));
                $('.dialog-widget-content').find('#popup-title>div>.elementor-heading-title').text($this.data('title'));
                $('.dialog-widget-content').find('#popup-weight .elementor-heading-title').text($this.data('weight'));
                $('.dialog-widget-content').find('#popup-width .elementor-heading-title').text($this.data('width'));

                var manufacturer = $('select.facetwp-hierarchy_select[data-level="0"]').val();
                if (manufacturer != ''){
                    manufacturer = $('select.facetwp-hierarchy_select[data-level="0"]').find('option[value='+manufacturer+']').text();
                    $('.dialog-widget-content').find('#form-field-field_hersteller').val(manufacturer);
                    $('label[for="form-field-field_hersteller"]').addClass('active');
                }

                var type = $('select.facetwp-hierarchy_select[data-level="1"]').val();
                if (type != '') {
                    type = $('select.facetwp-hierarchy_select[data-level="1"]').find('option[value=' + type + ']').text();
                    $('.dialog-widget-content').find('#form-field-field_typ').val(type);
                    $('label[for="form-field-field_typ"]').addClass('active');
                }

                clearInterval(checkExist);
            }
        }, 100);
    });

    if (document.querySelector('.facetwp-template')) {
        // Insert loading icon before the listing template
        $('<div class="loading-icon"></div>').insertBefore('.facetwp-template');
    
        // On start of the facet refresh, but not on first page load
        $(document).on('facetwp-refresh', function() {
        if ( FWP.loaded ) {
            $('.facetwp-template, .loading-icon').addClass('loading');
        }
        });

        // On finishing the facet refresh
        $(document).on('facetwp-loaded', function() {
        $('.facetwp-template, .loading-icon').removeClass('loading');
        });
    }

}(jQuery));
